import styles from '@styles/scss/common/_footer.module.scss';
import { FooterText } from 'lib/types/item/footer';
import { FooterLinks } from 'lib/types/item/footerLinks';

import UserFooterLinks from './userFooterLinks';
import { useEffect, useState } from 'react';

export default function Footer({
    footerText,
    footerLinks,
    currentLang,
    gender
}: {
    footerText: FooterText;
    footerLinks: FooterLinks;
    currentLang: string;
    gender: string;
}): JSX.Element {
  const [rightFooterText, setRightFooterText] = useState('');
    useEffect(() => {
      setRightFooterText(footerText.right_text);
    }, [footerText.right_text]);


    return (
        <footer>
            <div className={styles.footer}>
                <div className={styles.footerCol}>
                    <h2>{footerText.left_text_h2}</h2>
                    <p dangerouslySetInnerHTML={{ __html: footerText.left_text }} />
                    <h2>{footerText.right_text_h2}</h2>
                    <p dangerouslySetInnerHTML={{ __html: rightFooterText }} />
                    <hr></hr>
                    <hr></hr>
                    <UserFooterLinks
                        footerLinks={footerLinks}
                        currentLang={currentLang}
                        gender={gender}
                    />
                </div>
            </div>
        </footer>
    );
}
